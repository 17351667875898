import Auth from "components/Auth";
import Meta from "components/Meta";

export default function Login() {
  return (
    <>
      <Meta
        tags={{
          title: "IncDecentral: Login Your Dashboard | Incorporating Online",
          description:
            "Log in to to your account by clicking here. Stay connected with IncDecentral.",
        }}
      />
      <Auth />
    </>
  );
}
